<template>
	<div>
		<div class="modal-header"></div>
		<div class="modal-body">
			<h2 class="text-center mb-5">NOTICE</h2>
			<h4 class="text-center mb-5">Your session has expired</h4>
			<p class="text-format-2x text-center px-3">
				You have timed out, and for security reasons the session has been closed.
			</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button
				data-test-id="confirm-button"
				@click="reload"
				class="button-confirm mb-5 txt-uc"
				type="button"
			>
				START AGAIN
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'm-expired-session',

	methods: {
		reload() {
			window.document.location.reload();
		},
	},
};
</script>
